import { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import useGeoJson from '@/features/map/composables/useGeoJson';
import { FeatureDecorated } from '@/features/map/models/mapModels';
import { ShapefileInput, useSerializeShapeFileMutation } from '@/generated/graphql';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { computed, ref, unref } from 'vue';
import useIssuePhaseStyles from '@/features/issues/composables/useIssuePhaseStyles';
import { IssuePhase } from '@/features/issues/models';

export default function useShapeFileFeatures(phase?: MaybeRef<Maybe<Pick<IssuePhase, 'name'>>>) {
  const shapeFileFeatureCounter = ref(1);
  const shapeFileFeatures = ref<FeatureDecorated<Geometry>[]>([]);

  function shapeFileFeatureDelete(cId: number) {
    return () => {
      const idx = shapeFileFeatures.value.findIndex(({ counterId }) => counterId === cId);
      if (idx !== -1) {
        shapeFileFeatures.value.splice(idx, 1);
      }
    };
  }

  function shapeFileFeatureRender(feature: Feature<Geometry>, counterId: number) {
    return () => {
      shapeFileFeatures.value.push({ feature, counterId });
    };
  }

  function mapToShapeFileInput(files: Base64FilePayload[]): ShapefileInput {
    const input: ShapefileInput = {
      shx: '',
      shp: '',
      dbf: '',
    };

    for (const payload of files) {
      const ext = payload.file.name.split('.').slice(-1)[0];
      if (ext === 'shx' || ext === 'shp' || ext === 'dbf') {
        input[ext] = payload.base64;
      }
    }

    return input;
  }

  const { stringToGeometry } = useGeoJson();
  const { executeMutation: executeSerializeShapefileMutation } = useSerializeShapeFileMutation();
  const { phaseNameToFeatureStyle, defaultFeatureStyle } = useIssuePhaseStyles();

  async function serializeShapeFile(files: Base64FilePayload[]) {
    const input = mapToShapeFileInput(files);
    const res = await executeSerializeShapefileMutation({ input });
    const features: FeatureDecorated<Geometry>[] = [];
    for (const feature of res.data?.serializeShapefile.features || []) {
      const geom = stringToGeometry(feature.geometry);

      if (geom) {
        const importFeature = new Feature(geom);
        const renderShapeFileFeature = shapeFileFeatureRender(importFeature, shapeFileFeatureCounter.value);
        renderShapeFileFeature();
        const issuePhase = phase && unref(phase);
        importFeature.setStyle(issuePhase ? phaseNameToFeatureStyle(issuePhase.name) : defaultFeatureStyle);
        features.push({ feature: importFeature, delete: shapeFileFeatureDelete(shapeFileFeatureCounter.value), render: renderShapeFileFeature });
        shapeFileFeatureCounter.value = shapeFileFeatureCounter.value + 1;
      }
    }

    return features;
  }

  return {
    serializeShapeFile,
    shapeFileFeatures: computed(() => shapeFileFeatures.value.map(({ feature }) => feature as Feature<Geometry>)),
  };
}
