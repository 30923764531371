import useUploadFiles from '@/features/files/composables/useUploadFiles';
import { File } from '@/features/files/models';
import { AssetInput } from '@/features/files/models/AssetInput';
import { IssuePhaseAssetInput } from '@/features/files/models/IssuePhaseAssetInput';
import { IssueDetails, IssuePhase, IssuePhaseFile } from '@/features/issues/models';
import {
  IssuePhaseEnum,
  IssuePhaseFileTypes,
  IssuePhaseStepEnum,
  useAddFileToPhaseMutation,
  useCloseActivePhaseMutation,
  useMovePhaseStepMutation,
  useMoveCommunicationPhaseStepMutation,
  useMovePhasePrevStepMutation,
  usePublishActivePhaseMutation,
  useUpdateIssuePhaseMutation,
  useUpdateCommunicationPhaseMutation,
  useDraftActivePhaseMutation,
  useUpdatePhaseStepFieldMutation,
  useChangeReviewDatesMutation,
} from '@/generated/graphql';
import { UpdatePhaseInput } from '../models/UpdatePhaseInput';
import { unref } from 'vue';
import { UpdatePhaseStepFieldInput } from '../models/UpdatePhaseStepFieldInput';
type DateLike = Date | string;

interface AddFileToPhaseInput extends AssetInput {
  phaseId: string;
  fileType?: IssuePhaseFileTypes;
  iterationCounter?: number;
  stepType: IssuePhaseStepEnum;
}

const mapStepType = (stepType: Maybe<IssuePhaseStepEnum>): IssuePhaseFileTypes => {
  switch (stepType) {
    case IssuePhaseStepEnum.NidurstadaUmsagnarferlis:
      return IssuePhaseFileTypes.Vidbrogd;
    case IssuePhaseStepEnum.StadfestingSkipulagsstofnunar:
      return IssuePhaseFileTypes.Afgreidsla;
    case IssuePhaseStepEnum.UmsognSkipulagsstofnunar:
      return IssuePhaseFileTypes.Umsogn;
    default:
      return IssuePhaseFileTypes.Almennt;
  }
};

export default function useActivePhaseOperations(activeIssue: MaybeRef<Maybe<IssueDetails>>) {
  const { executeMutation: executeMovePhaseStepMutation } = useMovePhaseStepMutation();
  const { executeMutation: executeMovePhasePrevStepMutation } = useMovePhasePrevStepMutation();
  const { executeMutation: executeMoveCommunicationPhaseStepMutation } = useMoveCommunicationPhaseStepMutation();
  const { executeMutation: updateIssuePhaseMutation } = useUpdateIssuePhaseMutation();
  const { executeMutation: updateCommunicationPhaseMutation } = useUpdateCommunicationPhaseMutation();
  const { executeMutation: updatePhaseStepFieldMutation } = useUpdatePhaseStepFieldMutation();
  const { executeMutation: changeReviewDatesMutation } = useChangeReviewDatesMutation();
  const { executeMutation: draftActivePhaseMutation } = useDraftActivePhaseMutation();
  const { executeMutation: publishActivePhaseMutation } = usePublishActivePhaseMutation();
  const { executeMutation: closeActivePhaseMutation } = useCloseActivePhaseMutation();
  const { executeMutation: addFileToPhaseMutation } = useAddFileToPhaseMutation();
  const { uploadIssueActivePhaseFile } = useUploadFiles();

  const getStepPhaseFiles = (stepType?: IssuePhaseStepEnum, phaseType?: IssuePhaseEnum) => {
    var issue = unref(activeIssue);

    const mapPhaseFiles = (phaseFiles: IssuePhaseFile[]) => {
      return phaseFiles.filter(f => f.type === mapStepType(stepType)).map(f => ({ ...f.data, createdAt: f.createdAt, publishedAt: f.publishedAt }));
    };

    if (phaseType && stepType) {
      var phase = issue?.phases.filter(p => p.type == phaseType)[0];
      return phase ? mapPhaseFiles(phase.files) : [];
    } else {
      const { currentPhase } = issue || {};
      return currentPhase ? mapPhaseFiles(currentPhase.files) : [];
    }
  };

  const moveActivePhaseStep = async (stepId: ID): Promise<IssuePhase> => {
    const res = await executeMovePhaseStepMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      nextStepId: parseInt(stepId.toString()),
    });

    if (!res.data?.moveActivePhaseStep.phase) {
      // Todo: Error handling
      throw new Error('Failed to move phase step');
    }

    return res.data.moveActivePhaseStep.phase;
  };

  const moveActivePhasePrevStep = async (stepId: ID): Promise<IssuePhase> => {
    const res = await executeMovePhasePrevStepMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      prevStepId: parseInt(stepId.toString()),
    });

    if (!res.data?.moveActivePhasePrevStep.phase) {
      // Todo: Error handling
      throw new Error('Failed to move phase step');
    }

    return res.data.moveActivePhasePrevStep.phase;
  };

  const moveCommunicationPhaseStep = async (selectedStepType: IssuePhaseStepEnum): Promise<IssuePhase> => {
    const res = await executeMoveCommunicationPhaseStepMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      selectedStepType: selectedStepType,
    });

    if (!res.data?.moveCommunicationPhaseStep.phase) {
      // Todo: Error handling
      throw new Error('Failed to move phase step');
    }

    return res.data.moveCommunicationPhaseStep.phase;
  };

  const updateSelectedIssuePhase = async (rawInput: UpdatePhaseInput) => {
    const { quillDelta, ...input } = rawInput;
    if (quillDelta?.ops) {
      const opsJson = JSON.stringify(quillDelta.ops);
      input.richTextDescription = opsJson;
      const desc = quillDelta.ops.map(op => (typeof op.insert === 'string' ? op.insert : '')).join('');
      input.description = desc;
    }

    const res = await updateIssuePhaseMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      input,
    });

    if (!res.data?.updateIssuePhase.phase) {
      // Todo: Error handling
      throw new Error('Failed to update active phase');
    }

    return res.data.updateIssuePhase.phase;
  };

  const updateCommunicationPhase = async (stepType: IssuePhaseStepEnum, updateSelectedStep: boolean) => {
    const res = await updateCommunicationPhaseMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      stepType: stepType,
      updateSelectedStep: updateSelectedStep,
    });

    if (!res.data?.updateCommunicationPhase.phase) {
      // Todo: Error handling
      throw new Error('Failed to update active phase');
    }

    return res.data.updateCommunicationPhase.phase;
  };

  const updatePhaseStepField = async (phaseId: Maybe<string>, rawInput: UpdatePhaseStepFieldInput) => {
    const { quillDelta, ...input } = rawInput;
    if (quillDelta?.ops) {
      const opsJson = JSON.stringify(quillDelta.ops);
      input.richTextValue = opsJson;
      const desc = quillDelta.ops.map(op => (typeof op.insert === 'string' ? op.insert : '')).join('');
      input.value = desc;
    }

    const res = await updatePhaseStepFieldMutation({
      phaseId: phaseId || '',
      input,
    });

    if (!res.data?.updatePhaseStepField?.phase) {
      // Todo: Error handling
      throw new Error('Failed to update phase step field');
    }
    return res.data.updatePhaseStepField.phase;
  };

  const draftActivePhase = async (): Promise<IssuePhase> => {
    const res = await draftActivePhaseMutation({ issueId: unref(activeIssue)?.id.toString() || '' });

    if (!res.data?.draftActivePhase.issue?.currentPhase) {
      // Todo: Error handling
      throw new Error('Failed to draft active phase');
    }
    return res.data.draftActivePhase.issue.currentPhase;
  };

  const publishActivePhase = async (): Promise<IssuePhase> => {
    const res = await publishActivePhaseMutation({ issueId: unref(activeIssue)?.id.toString() || '' });

    if (!res.data?.publishActivePhase.issue?.currentPhase) {
      // Todo: Error handling
      throw new Error('Failed to publish active phase');
    }

    return res.data.publishActivePhase.issue.currentPhase;
  };

  const closeActivePhase = async (): Promise<IssuePhase> => {
    const res = await closeActivePhaseMutation({ issueId: unref(activeIssue)?.id.toString() || '' });

    if (!res.data?.closeActivePhase.issue?.currentPhase) {
      // Todo: Error handling
      throw new Error('Failed to publish active phase');
    }

    return res.data.closeActivePhase.issue.currentPhase;
  };

  const addFileToPhase = async (rawInput: AddFileToPhaseInput): Promise<IssuePhaseFile> => {
    const { phaseId, ...input } = rawInput;
    const ipaInput: IssuePhaseAssetInput = {
      ...input,
      fileType: input.fileType ?? IssuePhaseFileTypes.Almennt,
    };

    const uploadRes = await uploadIssueActivePhaseFile(unref(activeIssue)?.id.toString() || '', ipaInput);

    if (!uploadRes?.file) {
      throw new Error('Failed to add file');
    }

    await addFileToPhaseMutation({
      issueId: unref(activeIssue)?.id.toString() || '',
      phaseId: phaseId,
      fileId: uploadRes.file.id,
      fileType: input.fileType ?? IssuePhaseFileTypes.Almennt,
      iterationCounter: input.iterationCounter || 0,
      stepType: input.stepType,
    });

    const { file } = uploadRes;
    const issuePhaseFile: IssuePhaseFile = {
      data: file,
      published: file.published || true,
      type: ipaInput.fileType,
    };

    return issuePhaseFile;
  };

  const changeReviewDates = async (newReviewStartDate: DateLike, newReviewEndDate: DateLike): Promise<IssuePhase> => {
    const res = await changeReviewDatesMutation({
      phaseId: unref(activeIssue)?.currentPhase?.id.toString() || '',
      newReviewStartDate: newReviewStartDate,
      newReviewEndDate: newReviewEndDate,
    });

    if (!res.data?.changeReviewDates.issue?.currentPhase) {
      // Todo: Error handling
      throw new Error('Failed to change review dates');
    }
    return res.data.changeReviewDates.issue.currentPhase;
  };

  return {
    moveActivePhaseStep,
    moveActivePhasePrevStep,
    moveCommunicationPhaseStep,
    updateSelectedIssuePhase,
    updateCommunicationPhase,
    updatePhaseStepField,
    draftActivePhase,
    publishActivePhase,
    closeActivePhase,
    addFileToPhase,
    getStepPhaseFiles,
    changeReviewDates,
  };
}
