<script lang="ts" setup>
import InputText from '@/features/theme/base/InputText.vue';
import { computed, onMounted, ref, shallowRef, watch } from 'vue';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import IssueMap from '@/features/issues/components/issueMap/IssueMap.vue';
import Geometry from 'ol/geom/Geometry';
import Feature from 'ol/Feature';
import useGeoJson from '@/features/map/composables/useGeoJson';
import useProjections from '@/features/map/composables/useProjections';
import Point from 'ol/geom/Point';
import useIssueFeatures from '@/features/issues/composables/useIssueFeatures';
import { Issue, IssueDetails } from '@/features/issues/models';
import Article, { ArticleHeader, ArticleBody } from '@/features/theme/base/article';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import useIssueTags from '../../composables/useIssueTags';
import Translate from '@/features/translations/Translate.vue';
import IssueSearchRelated from '@/features/issueSearch/components/IssueSearchRelated.vue';
import IssueRelatedBox from '../IssueRelatedBox.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Icon from '@/features/theme/base/Icon.vue';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import { useI18n } from 'vue-i18n';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import useInputFiles from '@/features/files/composables/useInputFiles';
import FormInputFilesSection from '@/features/files/components/FormInputFilesSection.vue';
import OptionalPhasesFormGroup from './OptionalPhasesFormGroup.vue';
import Limit from '@/features/theme/base/Limit.vue';
import Combobox from '@/features/theme/base/combobox/Combobox.vue';
import { uniq } from 'lodash';
import useAuthStore from '@/features/auth/useAuthStore';
import SingleIssueAccessUserManagement from '@/features/userManagement/components/singleIssueAccess/SingleIssueAccessUserManagement.vue';
import { UpdateIssueInput } from '@/features/issueProcess/models/UpdateIssueInput';
import RichTextEditor from '@/features/theme/base/richTextEditor/RichTextEditor.vue';
import { Delta } from '@vueup/vue-quill';
import { Colors } from '@/features/theme/base/models/Colors';
import Modal from '@/features/theme/base/Modal.vue';
import useIssueContacts from '@/features/issues/composables/useIssueContacts';
import { IssueContact, IssueContactInput, IssueProcessCategoryEnum } from '@/generated/graphql';
import Spinner from '@/features/theme/base/Spinner.vue';
import { TranslationKey } from '@/features/translations/defaultMessages';
import useCommunityList from '@/features/communities/composables/useCommunityList';
import useCommunityGeographies from '@/features/communities/composables/useCommunityGeographies';
import { isFeatureCoordinatesEqual } from '../../issueGeometriesBlacklist';
import { nanoid } from 'nanoid';
import { IssueContactOp } from '../../models/IssueContactOp';

interface IssueContactInputWithId extends IssueContactInput {
  id: string;
}

const props = withDefaults(
  defineProps<{
    issue: IssueDetails;
    information: UpdateIssueInput;
    deleted: string[];
    errors?: Errors<UpdateIssueInput>;
    issueContacts?: Partial<IssueContact>[];
  }>(),
  {
    errors: () => ({}),
    issueContacts: () => [],
  }
);
const issueFiles = computed(() => props.issue.files ?? []);

const authStore = useAuthStore();

const emits = defineEmits<{
  (e: 'update:information', information: UpdateIssueInput): void;
  (e: 'update:deleted', deletedFileIds: string[]): void;
  (e: 'add:related', issueId: string): void;
  (e: 'remove:related', issue: Issue): void;
  (e: 'set:error', field: keyof UpdateIssueInput, message: string): void;
  (e: 'clear:error', field: keyof UpdateIssueInput): void;
  (e: 'update:issue-contacts', contacts: Partial<IssueContact>[]): void;
  (e: 'update:issue-contact', op: IssueContactOp, contact: Partial<IssueContact>): void;
}>();

const { featuresToGeoJson, geometryToGeoJson, stringToGeometry } = useGeoJson();
const { transformFeaturesIsToWgs, transformGeometryIsToWgs } = useProjections();
const { getIssueFeatures } = useIssueFeatures();

const onFieldUpdate = (field: keyof UpdateIssueInput, value: string) => {
  emits('update:information', { ...props.information, [field]: value });
  emits('clear:error', field);
};

const onRichTextUpdate = (delta: Maybe<Delta>) => {
  if (delta) {
    emits('update:information', { ...props.information, quillDelta: delta });
    emits('clear:error', 'description');
  }
};

const { tags } = useIssueTags(props.issue?.process?.issueTagType);
const isTagChecked = (tag: string): boolean => (props.information.tags || []).includes(tag);
const onTagChecked = (tag: string) => {
  const tags = props.information.tags || [];
  const index = tags.indexOf(tag);

  if (index === -1) {
    tags.push(tag);
  } else {
    tags.splice(index, 1);
  }
  emits('update:information', { ...props.information, tags });
};

const onRelatedSelected = (issueId: string) => {
  emits('add:related', issueId);
};
const onRelatedRemove = () => {
  emits('remove:related', props.issue);
};

const { t } = useI18n();
const { getProcessInformationKey } = useProcessTranslation();

const { communities } = useCommunityList();
const communityOptions = computed(() => communities.value.map((comm) => comm.name));
const { communities: selectedCommunities } = useCommunityGeographies(computed(() => props.information.communityIds || []));
const communityInput = ref<string>('');

const onCommunityUpdate = (value: string) => {
  communityInput.value = value;
  const c = communities.value.find(({ name }) => name === value);
  if (c) {
    selectCommunity(c);
  }
};

const selectCommunity = (community: { id: string }) => {
  const selected = [...(props.information.communityIds || [])];
  selected.push(community.id.toString());
  emits('update:information', { ...props.information, communityIds: uniq(selected) });
  emits('clear:error', 'communityIds');
};

const onCommunityRemove = (communityId: string, communityName: Maybe<string>) => {
  const c = communities.value.find(({ name }) => name === communityName);
  if (c) {
    selectCommunity(c);
  }
  emits('update:information', { ...props.information, communityIds: (props.information.communityIds || []).filter((id) => id !== communityId) });
};

const { findById } = useCollectionSearching();

const addCommunityToMap = watch(communities, () => {
  const issueCommunities = props.information.communityIds || [];
  if (props.issue.marker == 'null' && issueCommunities.length > 0 && communities.value.length > 0) {
    issueCommunities.forEach((community) => {
      var item = findById(communities.value, community);
      item ? onCommunityUpdate(item.name) : '';
    });
  }
  addCommunityToMap();
});

onMounted(() => {
  const issueCommunities = props.issue.communities || [];
  if (issueCommunities.length > 0) {
    communityInput.value = issueCommunities[0].name;
  }

  emits('update:information', { ...props.information, communityIds: issueCommunities.map(({ id }) => id.toString()) });
});

const { garbageFiles, inputAssets, newFiles, onFileDelete, onFileRestore, onFilesInput, onNewFileCancel, onNewFileEdit, remoteFiles, clearInputAssets } =
  useInputFiles(issueFiles);

watch(
  () => props.information.hasCommunitiesMapGeometries,
  (newValue) => {
    shouldAddCommunityMapMarker.value = !!newValue;
  }
);

watch(inputAssets, (current) => {
  emits('update:information', { ...props.information, files: [...current] });
});

watch(garbageFiles, (current) => {
  emits(
    'update:deleted',
    current.map((gf) => gf.id.toString())
  );
});

watch(
  () => props.information.files,
  (current) => {
    if (inputAssets.value?.length && !current?.length) {
      clearInputAssets();
    }
  }
);

const hasErrors = computed(() => Object.keys(props.errors).length > 0);
const errorMessages = computed(() => Object.values(props.errors).flatMap((f) => f));

const map = ref();

const translationKey = computed(() => {
  const processTitle = props.issue?.process?.title;
  return processTitle ? `${processTitle}.Grunnupplýsingar` : 'issue';
});

const { contacts, contactsLoading } = useIssueContacts(props.issue.id.toString());
const newContactNotSaved = ref(false);
const selectingContactFromList = ref<boolean>(false);

const onSelectContactFromList = () => {
  selectingContactFromList.value = true;
};

const onSelectContactFromListClose = () => {
  selectingContactFromList.value = false;
};

const isContactSelected = (contact: IssueContactInputWithId) => {
  return !!props.issueContacts.find(({ name, email }) => name === contact.name && email === contact.email);
};

const onContactSelect = (contact: Partial<IssueContact>, selected: boolean) => {
  const contacts = [...props.issueContacts];
  if (selected) {
    if (contact.name && contact.email) {
      contacts.push({ id: contact.id, name: contact.name, email: contact.email });
    }
  } else {
    const idx = contacts.findIndex(({ id, name, email }) => id === contact.id || (name === contact.name && email === contact.email));
    if (idx !== -1) {
      contacts.splice(idx, 1);
    }
  }
  emits('update:issue-contacts', contacts);
};

const onAddContactFields = () => {
  clearAlert();
  emits('update:issue-contact', IssueContactOp.Add, { id: nanoid(), name: '', email: '' });
};

const onRemoveContact = (contact: Partial<IssueContact>) => {
  emits('update:issue-contact', IssueContactOp.Remove, contact);
};

const issueContactsAll = computed(() => [...props.issueContacts]);

const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
const alertMessage = ref<Maybe<TranslationKey>>(null);
const alertType = ref<Maybe<AlertType>>(null);

const clearAlert = () => {
  alertMessage.value = null;
  alertType.value = null;
};

const shouldAddCommunityMapMarker = ref(!!props.information?.hasCommunitiesMapGeometries);

const getSelectedCommunityFeatures = () => {
  return selectedCommunities.value
    .filter((v) => v.geojson)
    .flatMap((v) => v.geojson?.features.map((f) => stringToGeometry(f.geometry)))
    .filter((g) => g)
    .map((g) => ({ feature: new Feature(g!) }));
};

const onAddCommunityMapMarker = () => {
  if (map.value) {
    shouldAddCommunityMapMarker.value = !shouldAddCommunityMapMarker.value;
    const selectedCommunityFeatures = getSelectedCommunityFeatures();

    if (shouldAddCommunityMapMarker.value) {
      selectedCommunityFeatures.forEach((scf) => map.value.addUniqueFeature(scf));
    } else {
      selectedCommunityFeatures.forEach((scf) => map.value.removeFeatureCompletely(scf));
    }
  }
};

const removedIssueFeatures = shallowRef<Feature<Geometry>[]>([]);
const onFeatureDelete = (feature: Feature<Geometry>) => {
  removedIssueFeatures.value = [...removedIssueFeatures.value, feature];
};
const issueFeatures = computed(() => {
  let features = getIssueFeatures(props.issue);
  if (!shouldAddCommunityMapMarker.value) {
    const selecteCommunityFeatures = getSelectedCommunityFeatures();
    features = features.filter((feature) => {
      return !selecteCommunityFeatures.some((scf) => isFeatureCoordinatesEqual(scf, feature));
    });
  }
  return features.filter((f) => !removedIssueFeatures.value.find((del) => isFeatureCoordinatesEqual(del, f)));
});

const updateIssueFeatures = (features: Feature<Geometry>[]) => {
  const issueFeaturesMerged = [...issueFeatures.value];
  features.forEach((feature) => {
    if (!issueFeatures.value.some((issf) => isFeatureCoordinatesEqual(issf, feature))) {
      issueFeaturesMerged.push(feature);
    }
  });

  const markerGeom = issueFeaturesMerged[0]?.getGeometry();
  let marker;
  if (markerGeom) {
    const extent = markerGeom.getExtent();
    const x = extent[0] + (extent[2] - extent[0]) / 2;
    const y = extent[1] + (extent[3] - extent[1]) / 2;
    marker = geometryToGeoJson(transformGeometryIsToWgs(new Point([x, y])));
  }

  emits('update:information', {
    ...props.information,
    geometries: {
      geoJson: featuresToGeoJson(transformFeaturesIsToWgs(issueFeaturesMerged)),
    },
    marker,
    hasCommunitiesMapGeometries: shouldAddCommunityMapMarker.value,
  });

  emits('clear:error', 'geometries');
};

const showAddCommunityMapGeography = computed(() => {
  return (
    props.issue.process?.category?.type &&
    [IssueProcessCategoryEnum.Adalskipulag, IssueProcessCategoryEnum.Svaedisskipulag].includes(props.issue.process?.category?.type)
  );
});

const showMissingDelegationWarning = computed(() => {
  return !props.issue.delegation;
});

// Key down to map layers, can be used to force re-render
const issueMapDrawKey = ref(1);
watch(
  computed(() => props.issue),
  () => {
    issueMapDrawKey.value = issueMapDrawKey.value + 1;
  }
);
</script>

<template>
  <Article>
    <ArticleHeader>
      <Translate
        :value="getProcessInformationKey(issue.process)"
        :default-value="t('issue.information')"
      />
      <template #subtitle>
        <Translate
          :value="getProcessInformationKey(issue.process, 'help')"
          :default-value="t('issue.information.subtitle')"
        />
      </template>
    </ArticleHeader>
    <ArticleBody>
      <LayoutForm>
        <LayoutFormGroup>
          <LayoutInput>
            <InputText
              :model-value="information.title"
              label="issue.title"
              @update:model-value="(val) => onFieldUpdate('title', val)"
              fullwidth
              :error-message="errors.title?.[0]"
            />
          </LayoutInput>
          <LayoutInput>
            <Combobox
              label="base.community"
              :error-message="errors.communityIds?.[0]"
              :options="communityOptions"
              @update:model-value="onCommunityUpdate"
              :model-value="communityInput"
              scroll="small"
            />
          </LayoutInput>
        </LayoutFormGroup>

        <LayoutFormGroup v-if="(information.communityIds || []).length > 0">
          <LayoutInput :span="2">
            <ul>
              <li
                class="community__item mb-1"
                v-for="community in information.communityIds"
                :key="community"
              >
                <InputText
                  readonly
                  fullwidth
                  :model-value="findById(communities, community)?.name"
                  label="community.selected"
                />
                <Button
                  :type="ButtonType.tertiaryIconOnly"
                  @click="() => onCommunityRemove(community, findById(communities, community)?.name)"
                  prevent
                >
                  <Icon icon="Delete" />
                </Button>
              </li>
            </ul>
          </LayoutInput>
        </LayoutFormGroup>

        <LayoutFormGroup>
          <LayoutInput :span="2">
            <RichTextEditor
              translationKey="issue.description"
              :model-value="information.quillDelta"
              @update:model-value="(val) => onRichTextUpdate(val)"
              fullwidth
              :error-message="errors.description?.[0]"
            />
          </LayoutInput>
        </LayoutFormGroup>

        <OptionalPhasesFormGroup
          :active-issue="issue"
          v-model:skipped="information.skippedPhaseIds"
        />

        <FormInputFilesSection
          v-if="issue.process.hasIssueFiles"
          :own-files="remoteFiles"
          :garbage-files="garbageFiles"
          :new-files="newFiles"
          :translation-key="translationKey"
          @on-file-delete="onFileDelete"
          @on-file-restore="onFileRestore"
          @on-files-input="onFilesInput"
          @on-new-file-cancel="onNewFileCancel"
          @on-new-file-edit="onNewFileEdit"
        />

        <template v-if="issue?.process?.hasRelatedIssues">
          <LayoutFormGroup title="issue.related_issues">
            <template #explanation>
              <Translate t="issue.related_issues.help" />
            </template>
            <LayoutInput :span="2">
              <div class="flex">
                <IssueSearchRelated
                  :disabled="issue.relatedIssues.length > 0"
                  class="flex-grow"
                  @select="onRelatedSelected"
                />
                <Button
                  class="ml-1"
                  v-if="issue.relatedIssues.length > 0"
                  :type="ButtonType.tertiaryIconOnly"
                  @click="onRelatedRemove"
                >
                  <Icon icon="Delete" />
                </Button>
              </div>
            </LayoutInput>
          </LayoutFormGroup>

          <LayoutFormGroup>
            <LayoutInput
              v-for="related in issue.relatedIssues"
              :key="related.id"
              :span="1"
            >
              <div class="flex gap-1 issue-related-container">
                <IssueRelatedBox
                  class="related-box"
                  :issue="related"
                />
              </div>
            </LayoutInput>
          </LayoutFormGroup>
        </template>

        <template v-if="true">
          <LayoutFormGroup title="issue.contact.inquiries">
            <template #explanation><Translate t="issue.contact.inquiries.info"></Translate></template>
          </LayoutFormGroup>

          <LayoutFormGroup>
            <LayoutInput>
              <Button
                :type="ButtonType.secondary"
                @click="onSelectContactFromList"
              >
                <span class="mr-1">
                  <Translate t="issue.contact.list" />
                </span>
                <Icon
                  icon="Plus"
                  :options="{ width: 24, height: 24, color: Colors.primaryDark }"
                />
              </Button>
            </LayoutInput>
            <LayoutInput>
              <Button
                @click="onAddContactFields"
                :type="ButtonType.secondary"
                :disabled="newContactNotSaved"
              >
                <span class="mr-1">
                  <Translate t="issue.contact.add" />
                </span>
                <Icon
                  icon="Plus"
                  :options="{ width: 24, height: 24, color: Colors.primaryDark }"
                />
              </Button>
            </LayoutInput>
          </LayoutFormGroup>

          <LayoutFormGroup
            v-for="(contact, index) in issueContactsAll"
            :key="contact.id ?? index"
          >
            <LayoutInput>
              <InputText
                fullwidth
                :label="'issue.contact.name'"
                :model-value="contact.name"
                @update:model-value="
                  (value) => {
                    contact.name = value;
                    emits('update:issue-contact', IssueContactOp.Update, contact);
                  }
                "
              />
            </LayoutInput>
            <LayoutInput>
              <InputText
                fullwidth
                :label="'issue.contact.email'"
                :model-value="contact.email"
                @update:model-value="
                  (value) => {
                    contact.email = value;
                    emits('update:issue-contact', IssueContactOp.Update, contact);
                  }
                "
              />
            </LayoutInput>
            <LayoutInput class="flex gap-1">
              <Button
                @click="() => onRemoveContact(contact)"
                :type="ButtonType.tertiaryIconOnly"
                v-tooltip="{ content: t('issue.tooltip.delete.contact'), theme: 'primary' }"
              >
                <Icon icon="Delete" />
              </Button>
            </LayoutInput>
          </LayoutFormGroup>
          <Alert
            v-if="alertMessage && alertType"
            :alert-type="alertType"
          >
            <Translate :t="alertMessage" />
          </Alert>
        </template>

        <LayoutFormGroup
          title="base.tags"
          v-if="issue?.process?.hasTags"
        >
          <template #explanation>
            <Translate t="issue.tags.help" />
          </template>
          <LayoutInput :span="3">
            <Limit fullwidth>
              <div class="checkbox-list">
                <Checkbox
                  @update:model-value="() => onTagChecked(tag)"
                  :model-value="isTagChecked(tag)"
                  class="mb-2"
                  v-for="tag in tags"
                  :key="tag"
                  :id="`tag-${tag}`"
                >
                  {{ tag }}
                </Checkbox>
              </div>
            </Limit>
          </LayoutInput>
        </LayoutFormGroup>

        <SingleIssueAccessUserManagement
          v-if="authStore.isSpecialAccess && issue?.delegationId"
          :issue="issue"
        />

        <LayoutFormGroup title="base.location">
          <template #explanation>
            <Translate t="issue.map.help" />
          </template>
          <LayoutInput
            :span="3"
            v-if="selectedCommunities?.length && showAddCommunityMapGeography"
          >
            <Checkbox
              @update:model-value="onAddCommunityMapMarker"
              :model-value="shouldAddCommunityMapMarker"
              class="mb-2"
              id="shouldAddCommunityMapMarker"
            >
              <Translate
                v-if="selectedCommunities?.length === 1"
                t="issue.map.addCommunityMapMarker"
              />
              <Translate
                v-else-if="selectedCommunities?.length > 1"
                t="issue.map.addCommunitiesMapMarker"
              />
            </Checkbox>
          </LayoutInput>
          <LayoutInput
            :span="3"
            :style="{ minHeight: '50vh' }"
          >
            <IssueMap
              ref="map"
              :draw-key="issueMapDrawKey"
              class="map"
              drawable
              :issue="issue"
              @features="updateIssueFeatures"
              @delete="onFeatureDelete"
              importable
              deletable
            />
          </LayoutInput>
        </LayoutFormGroup>
      </LayoutForm>
    </ArticleBody>
    <ArticleBody v-if="hasErrors">
      <Alert
        :alert-type="AlertType.danger"
        title="validation.submit"
      >
        <ul>
          <li v-for="error in errorMessages">{{ error }}</li>
        </ul>
      </Alert>
    </ArticleBody>
    <Modal
      :open="selectingContactFromList"
      @close="onSelectContactFromListClose"
      scrollable
    >
      <Article>
        <ArticleHeader>
          <Translate t="issue.contact.list" />
        </ArticleHeader>
        <ArticleBody>
          <div v-if="!contactsLoading">
            <Checkbox
              v-for="contact in contacts"
              :key="`contact-${contact.id}`"
              :model-value="isContactSelected(contact)"
              @update:model-value="(selected: boolean) => onContactSelect(contact, selected)"
              :id="contact.name"
            >
              {{ contact.name }}
              ({{ contact.email }})
            </Checkbox>
            <div class="flex flex-space-between mt-4">
              <Button
                :type="ButtonType.secondary"
                @click="onSelectContactFromListClose"
              >
                <Translate t="base.cancel" />
              </Button>
              <Button
                :type="ButtonType.secondary"
                @click="onSelectContactFromListClose"
              >
                <Translate t="issue.contact.confirm" />
              </Button>
            </div>
          </div>
          <div
            v-else
            class="flex flex-center flex-column"
          >
            <Spinner />
            <Translate t="base.loading" />
          </div>
        </ArticleBody>
      </Article>
    </Modal>
  </Article>
</template>
<style lang="scss" scoped>
.map {
  height: 60vh;
}

.time {
  display: flex;
  justify-content: space-between;
}

.checkbox-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.flex-grow {
  flex-grow: 1;
}

.issue-related-container {
  align-items: center;
}

.related-box {
  flex-grow: 1;
}

.community__item {
  display: flex;
  align-items: center;

  .community__title {
    flex-grow: 1;
  }
}
</style>
