<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { File as DomainFile } from '@/features/files/models';
import Translate from '@/features/translations/Translate.vue';
import FileList from '@/features/files/components/FileList.vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';

const props = defineProps<{
  ownFiles: DomainFile[];
  garbageFiles: DomainFile[];
  newFiles: DomainFile[];
  translationKey: string;
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFilesInput', payload: Base64FilePayload[]): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onNewFileCancel', file: DomainFile, index: number): void;
  (event: 'onNewFileEdit', name: string, index: number): void;
}>();

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onFilesInput = (payload: Base64FilePayload[]) => {
  emits('onFilesInput', payload);
};

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onNewFileCancel = (file: DomainFile, index: number) => {
  emits('onNewFileCancel', file, index);
};

const onNewFileEdit = (name: string, index: number) => {
  emits('onNewFileEdit', name, index);
};
</script>

<template>
  <LayoutFormGroup title="issue.phase.file_input">
    <template #explanation>
      <Translate
        :value="`${translationKey}.files`"
        default-value="Gögn málsins"
      />
    </template>
    <LayoutInput :span="2">
      <FileList
        :show-registration-dates="true"
        :files="ownFiles"
        editable
        deletable
        @delete="onFileDelete"
      />
    </LayoutInput>
  </LayoutFormGroup>
  <LayoutFormGroup class="mb-4">
    <LayoutInput>
      <FileInputBase64 @files="onFilesInput" />
    </LayoutInput>
  </LayoutFormGroup>
  <LayoutFormGroup v-if="garbageFiles.length > 0">
    <template #explanation>
      <Translate t="issue.garbage_files" />
    </template>
    <LayoutInput :span="2">
      <FileList
        :files="garbageFiles"
        deletable
        @delete="onFileRestore"
      />
    </LayoutInput>
  </LayoutFormGroup>
  <LayoutFormGroup v-if="newFiles.length > 0">
    <template #explanation>
      <Translate t="issue.new_files" />
    </template>
    <LayoutInput :span="2">
      <FileList
        :files="newFiles"
        :download="false"
        deletable
        editable
        @delete="onNewFileCancel"
        @edit="onNewFileEdit"
      />
    </LayoutInput>
  </LayoutFormGroup>
</template>
