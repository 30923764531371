<script lang="ts" setup>
import LoginPrompt from '@/features/auth/LoginPrompt.vue';
import useAuthUser from '@/features/auth/useAuthUser';
import Article, { ArticleBody, ArticleHeader } from '@/features/theme/base/article';
import Button from '@/features/theme/base/Button.vue';
import Modal from '@/features/theme/base/Modal.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { computed, ref } from 'vue';
import { IssueDetails, IssuePhase } from '../../models';
import IssueReviewList from './IssueReviewList.vue';
import IssueReviewForm from './IssueReviewForm.vue';
import useIssuePhaseReviewDates from '@/features/issues/composables/useIssuePhaseReviewDates';
import { useInfiniteScroll } from '@vueuse/core';

interface Props {
  issue: IssueDetails;
  phase: IssuePhase;
  canAlwaysWriteReview?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  canAlwaysWriteReview: false,
});

const phase = computed(() => props.phase);
const issue = computed(() => props.issue);

const { isAuthenticated } = useAuthUser();
const loginPrompt = ref<boolean>(false);
const onLoginCancel = () => {
  loginPrompt.value = false;
};

const showReviewModal = ref<boolean>(false);
const onWriteReviewClick = () => {
  if (isAuthenticated.value) {
    showReviewModal.value = true;
  } else {
    loginPrompt.value = true;
  }
};
const onReviewModalClose = () => {
  showReviewModal.value = false;
};

const { isAdmin } = useAuthUser();

const { isOpenForReview } = useIssuePhaseReviewDates(phase);
const canWriteReview = computed<boolean>(() => {
  if (props.canAlwaysWriteReview) {
    return true;
  }

  return isOpenForReview.value;
});

const pageSize = ref(8);
const visibleReviews = computed(() => props.phase.reviews.filter(({ isHidden }) => isAdmin.value || !isHidden).slice(0, pageSize.value));
const visibleReviewsTotalCount = computed(() => props.phase.reviews.filter(({ isHidden }) => isAdmin.value || !isHidden).length || 0);

const hasPrivateReviews = computed(() => {
  return !!phase.value.hasPrivateReviews;
});

const listContainerRef = ref<HTMLElement | null>(null);

useInfiniteScroll(
  listContainerRef,
  () => {
    if (pageSize.value <= phase.value.reviews.length) {
      pageSize.value += 8;
    }
  },
  { distance: 220 }
);
</script>
<template>
  <Article>
    <ArticleHeader>
      <div class="flex flex-wrap gap-1 review-header">
        <div>
          <Translate
            t="issue.reviews"
            :interpolations="{ n: visibleReviewsTotalCount }"
          />
        </div>
        <Button
          v-if="canWriteReview"
          @click="onWriteReviewClick"
        >
          <Translate t="issue.review.write" />
          <Icon
            class="icon-right"
            icon="Plus"
            :options="{ width: 24, height: 24, color: Colors.white }"
          />
        </Button>
      </div>
    </ArticleHeader>
    <ArticleBody>
      <div
        ref="listContainerRef"
        class="review-list-container"
      >
        <IssueReviewList
          :issue-id="phase.issueId"
          :phase-id="phase.id"
          :reviews="visibleReviews"
        />
      </div>
    </ArticleBody>
    <Modal
      :open="showReviewModal"
      @close="onReviewModalClose"
      scrollable
    >
      <div class="p-4 bg-white">
        <IssueReviewForm
          @submit="onReviewModalClose"
          :phase="phase"
          :issue="issue"
          @cancel="onReviewModalClose"
          :is-private="hasPrivateReviews"
        />
      </div>
    </Modal>

    <LoginPrompt
      :prompt="loginPrompt"
      @cancel="onLoginCancel"
    >
      <template #help>
        <Translate t="issue.review.prompt" />
      </template>
    </LoginPrompt>
  </Article>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.review-header {
  justify-content: space-between;
  align-items: center;

  @include mq.mobile {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.review-list-container {
  max-height: 180vh;
  overflow: hidden auto;
}
</style>
