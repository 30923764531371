<script lang="ts" setup>
import useAuthUser from '@/features/auth/useAuthUser';
import useDateFormatting from '@/features/composables/useDateFormatting';
import FileList from '@/features/files/components/FileList.vue';
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { computed, ref } from 'vue';
import useIssueReviewOperations from '../../composables/useIssueReviewOperations';
import { IssueReview } from '../../models';
import RichText from '@/features/theme/base/richTextEditor/RichText.vue';
import Confirm from '@/features/theme/base/Confirm.vue';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';

const props = withDefaults(
  defineProps<{
    issueId: ID;
    phaseId: ID;
    review: IssueReview;
    number?: number;
  }>(),
  {
    number: 0,
  }
);

const { formatDateLocalized } = useDateFormatting();

const reviewer = computed<Maybe<string>>(() => {
  if (props.review.statutoryReviewer) {
    return props.review.statutoryReviewer.name;
  }
  if (props.review.extraRequiredReviewer) {
    return props.review.extraRequiredReviewer.name;
  }
});

const { user, isAdmin } = useAuthUser();
const isAuthUserReview = computed<boolean>(() => {
  return !!(user.value?.id && user.value.id === props.review.user?.id) || isAdmin.value;
});

const { deleteReview, updateReview } = useIssueReviewOperations();

const showDeleteWarning = ref(false);

const onDeleteClick = () => {
  showDeleteWarning.value = true;
};

const onHideClick = () => {
  updateReview(props.issueId, props.review.id, {
    isHidden: !props.review.isHidden,
  });
};

const onYesDelete = async () => {
  await deleteReview(props.issueId, props.review.id);
  showDeleteWarning.value = false;
};

const onNoDelete = () => {
  showDeleteWarning.value = false;
};
</script>

<template>
  <li
    class="review p-4"
    :class="{ 'is-hidden': review.isHidden }"
  >
    <div class="flex review-header">
      <div v-if="reviewer">
        <div class="text-primary bold subtitle">{{ reviewer }}</div>
        <div class="text-grey-600">{{ review.user?.fullName }}</div>
      </div>
      <div v-else>
        <span
          class="user-initial mr-1"
          :class="{ self: isAuthUserReview }"
        >
          {{ review.user?.fullName[0] }}
        </span>
        <span class="user-name text-grey-600">{{ review.user?.fullName }}</span>
      </div>
      <div class="review-date text-primary">
        <Translate t="issue.review.published" />
        <span class="bold">{{ formatDateLocalized(review.createdAt) }}</span>
      </div>
    </div>
    <div class="content">
      <RichText
        :opsJson="review.richTextComment"
        :rawText="review.comment"
      />
    </div>
    <div
      v-if="review.files"
      class="files"
    >
      <FileList :files="review.files" />
    </div>
    <div class="review__footer">
      <div
        class="text-primary bold"
        v-if="number > 0"
      >
        &#35; {{ number }}
      </div>
      <div class="buttons">
        <Button
          v-if="isAdmin"
          @click="onHideClick"
          :type="ButtonType.tertiary"
        >
          <Translate :t="review.isHidden ? 'issue.review.show' : 'issue.review.hide'" />
          <Icon
            :icon="review.isHidden ? 'EyeSlash' : 'Eye'"
            :options="{ height: 24, width: 24, color: Colors.primaryDark }"
            class="icon-right"
          />
        </Button>
        <Button
          v-if="isAuthUserReview"
          @click="onDeleteClick"
          :type="ButtonType.tertiary"
        >
          <Translate t="issue.review.delete" />
          <Icon
            icon="Delete"
            :options="{ height: 24, width: 24, color: Colors.primaryDark }"
            class="icon-right"
          />
        </Button>
      </div>
    </div>
    <Confirm
      :active="showDeleteWarning"
      @yes="onYesDelete"
      @no="onNoDelete"
    >
      <Alert
        title="base.warning"
        :alert-type="AlertType.danger"
      >
        <Translate :t="'issue.review.delete.warning'" />
      </Alert>
    </Confirm>
  </li>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;

.review {
  display: flex;
  flex-direction: column;
  gap: spacing.$gutter-large;
  border: 1px solid colors.$grey-300;

  @include mq.mobile {
    padding: 2.4rem;
    gap: spacing.$space-large;
  }
}

.review-header {
  justify-content: space-between;
  display: flex;
  min-height: 2rem;
  align-items: center;

  @include mq.mobile {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

.review-date {
  text-transform: uppercase;
  font-size: 1.4rem;
}

.user-initial {
  padding: 0.5rem;
  background: colors.$grey-200;

  &.self {
    color: colors.$white;
    background-color: colors.$primary--dark;
  }
}

.user-name {
  @include mq.mobile {
    display: block;
    margin-top: 1rem;
  }
}

.files {
  max-width: 100%;

  @include mq.laptop() {
    max-width: 50%;
  }
}

.review__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq.mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex-grow: 1;

  @include mq.mobile {
    flex-direction: column;
  }
}

.is-hidden {
  opacity: 0.6;
}
</style>
