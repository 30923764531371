<script lang="ts" setup>
import Article, { ArticleText, ArticleHeader } from '@/features/theme/base/article';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import FileList from '@/features/files/components/FileList.vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import useActivePhaseOperations from '@/features/issueProcess/composables/useActivePhaseOperations';
import { IssuePhaseFileTypes, IssuePhaseStepEnum } from '@/generated/graphql';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import Translate from '@/features/translations/Translate.vue';
import PhaseStepTextAreaFormGroup from '@/features/issueProcess/components/phaseSteps/PhaseStepTextAreaFormGroup.vue';
import { File as DomainFile } from '@/features/files/models';
import useActiveIssue from '../../composables/useActiveIssue';
import { computed } from 'vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
  translationKey: string;
  garbageFiles: DomainFile[];
  ownFiles: DomainFile[];
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
}>();

const { activePhaseSelected, activePhase, selectedPhase } = useActiveIssue(computed(() => props.issue));
const { addFileToPhase } = useActivePhaseOperations(computed(() => props.issue));

const onFilesInput = (payload: Base64FilePayload[]) => {
  for (const item of payload) {
    if (selectedPhase.value && ((selectedPhase.value.isCommunicationPhase && activePhaseSelected) || !selectedPhase.value.isCommunicationPhase)) {
      addFileToPhase({
        phaseId: selectedPhase.value.id.toString(),
        data: item.base64,
        name: item.file.name,
        encoding: 1,
        iterationCounter: 0,
        fileType: IssuePhaseFileTypes.Vidbrogd,
        stepType: IssuePhaseStepEnum.NidurstadaUmsagnarferlis,
      });
    } else {
      console.error("Can't upload if selected phase has no value!");
    }
  }
};

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};
</script>
<template>
  <Article>
    <ArticleHeader>
      <Translate
        :value="`${translationKey}.name`"
        default-value="Niðurstöður í vinnslu"
      />
    </ArticleHeader>
    <ArticleText>
      <Translate
        :value="`${translationKey}.help`"
        default-value="Hér getur þú farið yfir niðurstöður og sett inn viðbrögð við umsögnum í fyrra skrefi"
      />
    </ArticleText>
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <PhaseStepTextAreaFormGroup
            :phase-id="phase.id.toString()"
            :phase-step-type="IssuePhaseStepEnum.NidurstadaUmsagnarferlis"
            field-key="TextBoxi"
            :active-issue="props.issue"
          />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup v-if="ownFiles.length > 0">
        <LayoutInput :span="2">
          <FileList
            :show-registration-dates="true"
            :files="ownFiles"
            @delete="onFileDelete"
            editable
            deletable
          />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput>
          <FileInputBase64 @files="onFilesInput" />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup v-if="garbageFiles.length > 0">
        <template #explanation>
          <Translate t="issue.garbage_files" />
        </template>
        <LayoutInput :span="2">
          <FileList
            :files="garbageFiles"
            deletable
            @delete="onFileRestore"
          />
        </LayoutInput>
      </LayoutFormGroup>
    </LayoutForm>
  </Article>
</template>
