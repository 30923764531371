//@ts-nocheck
import { createI18n } from 'vue-i18n';
import { client } from '@/urql';
import { GetTranslationsDocument, Message, Translation } from '@/generated/graphql';
import messages from './defaultMessages';

function mapMessages(translations: Message[]) {
  return translations.reduce((prev: Record<string, string>, curr) => {
    prev[curr.key] = curr.value || curr.defaultValue || '';
    return prev;
  }, {});
}

const i18n = createI18n({
  locale: 'is-IS', // set locale
  fallbackLocale: 'is-IS',

  messages: {
    'is-IS': messages, // set default locale messages
  },

  // Disables console warning noise, should consider flipping this with a .env var
  missingWarn: false,
  fallbackWarn: false,

  silentTranslationWarn: true,
  silentFallbackWarn: true,
  legacy: false,
  // If you need to specify other options, you can set other options
  // ...
  messageResolver: (obj, path) => {
    if (obj && obj[path]) {
      return typeof obj[path] === 'string' ? obj[path].replaceAll(/@/g, "{'@'}") : obj[path];
    }

    return null;
  },
});

export async function getMessages(areaId: ID) {
  const result = await client.query<{ translations: Translation[] }>(GetTranslationsDocument, { areaId }).toPromise();
  result.data?.translations.forEach(({ locale, messages }) => {
    i18n.global.mergeLocaleMessage(locale, mapMessages(messages));
  });
}

export default i18n;
