import { useMediaQuery as vueUseMediaQuery } from '@vueuse/core';

export default function useMediaQuery() {
  return {
    mobile: vueUseMediaQuery('(max-width: 599px)'),
    tablet: vueUseMediaQuery('(min-width: 600px)'),
    laptop: vueUseMediaQuery('(min-width: 1240px)'),
    desktop: vueUseMediaQuery('(min-width: 1440px)'),
    fullhd: vueUseMediaQuery('(min-width: 1920px)'),
  };
}
