<script lang="ts" setup>
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import { IssuePhaseStepEnum, IssuePhaseFileTypes } from '@/generated/graphql';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import Translate from '@/features/translations/Translate.vue';
import IssuePhaseStepCommunications from '@/features/issueProcess/components/phaseSteps/IssuePhaseStepCommunications.vue';
import PhaseStepTextAreaFormGroup from '@/features/issueProcess/components/phaseSteps/PhaseStepTextAreaFormGroup.vue';
import { File as DomainFile } from '@/features/files/models';
import useActiveIssue from '../../composables/useActiveIssue';
import useActivePhaseOperations from '../../composables/useActivePhaseOperations';
import { computed } from 'vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import FileList from '@/features/files/components/FileList.vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
  ownFiles: DomainFile[];
  garbageFiles: DomainFile[];
  stepType: IssuePhaseStepEnum;
  fileType: IssuePhaseFileTypes;
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onCommentDelete = (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  emits('onCommentDelete', stepType, iterationCounter);
};

const { selectedPhase } = useActiveIssue(computed(() => props.issue));
const { addFileToPhase } = useActivePhaseOperations(computed(() => props.issue));

const onFilesInput = (payload: Base64FilePayload[]) => {
  for (const item of payload) {
    if (selectedPhase.value) {
      addFileToPhase({
        data: item.base64,
        name: item.file.name,
        phaseId: selectedPhase.value.id.toString(),
        encoding: 1,
        iterationCounter: 0,
        fileType: props.fileType,
        stepType: props.stepType,
      });
    } else {
      console.error("Can't upload if selected phase has no value!");
    }
  }
};
</script>
<template>
  <LayoutForm v-if="selectedPhase?.isCommunicationPhase">
    <IssuePhaseStepCommunications
      :active-issue="props.issue"
      :phase-id="phase.id.toString()"
      :phase-step-type="props.stepType"
      :garbage-files="props.garbageFiles"
      @on-file-delete="onFileDelete"
      @on-file-restore="onFileRestore"
      @on-comment-delete="onCommentDelete"
      field-key="TextBoxi"
      :file-type="props.fileType"
    />
  </LayoutForm>
  <LayoutForm v-else>
    <LayoutFormGroup>
      <LayoutInput :span="2">
        <PhaseStepTextAreaFormGroup
          :phase-id="phase.id.toString()"
          :phase-step-type="props.stepType"
          field-key="TextBoxi"
          :active-issue="props.issue"
        />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup v-if="ownFiles.length > 0">
      <LayoutInput :span="2">
        <FileList
          :show-registration-dates="true"
          :files="ownFiles"
          @delete="onFileDelete"
          editable
          deletable
        />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup>
      <LayoutInput>
        <FileInputBase64
          @files="onFilesInput"
          deletable
          editable
        />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup v-if="garbageFiles.length > 0">
      <template #explanation>
        <Translate t="issue.garbage_files" />
      </template>
      <LayoutInput :span="2">
        <FileList
          :files="garbageFiles"
          deletable
          @delete="onFileRestore"
        />
      </LayoutInput>
    </LayoutFormGroup>
  </LayoutForm>
</template>
